<script src="../../../api/goods.js"></script>
<template>
  <!-- 商品组件 activityComponentType:19 -->
  <div class="m-row goods">
    <!-- 标签栏 -->
    <div class="tabs">
      <div v-for="(itemSub,index) in config.arrType" @click="onSwitch(itemSub)" class="tabs-item" :class="config.colTabGoodsType==itemSub.sortId?'active':''">{{itemSub.goodsSortName}}
        <div v-if="config.colTabGoodsType==itemSub.sortId" class="current-filter"></div>
      </div>
    </div>
    <!-- "moreFlag": 1,--更多内容标识（1显示，0隐藏） -->
    <div class="goods-more" v-if="config.moreFlag==1" @click="onJumpGoodsCenter">查看更多<van-icon name="arrow" size='15'/></div>
    <!-- 商品卡片 -->
    <goods-list :arrGoods="config.arrGoods"></goods-list>
  </div>
</template>

<script>
import api from '@/utils/api'
import goodsList from '@/views/components/goods-list/index.vue'
import { getSortPageGoods } from '@/api/goods'

export default {
  components: {
    goodsList
  },

  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      colTabGoodsType: 1
    }
  },

  methods: {
    // 商品-切换tab
    onSwitch(item){
      let colTabGoodsType = item.sortId
      let param = {
        clinicTopicCode: this.config.clinicTopicCode,
        clinicTopicSubCode: this.config.clinicTopicSubCode,
        sortId: item.sortId,
        isMore: 0
      }
      getSortPageGoods(param).then((res) => {
        let arrGoods = res.data || []
        this.$emit('update-value', {
          activityComponentType: this.config.activityComponentType,
          clinicTopicSubCode: this.config.clinicTopicSubCode,
          arrGoods: arrGoods,
          colTabGoodsType: colTabGoodsType
        })
      }).catch(err => {
        api.toast(err.message)
      })
    },

    // 商品-跳转更多商品
    onJumpGoodsCenter(){
      api.jumpPage('goods-center', {
        clinicTopicCode: this.config.clinicTopicCode,
        clinicTopicSubCode: this.config.clinicTopicSubCode,
        sortId: this.config.colTabGoodsType
      })
    }
  }

}
</script>

<style lang="scss" scoped>

.goods .tabs {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: nowrap;
  font-size: 32px;
  color: #666666;
  position: relative;
  overflow: auto;
  width: 100%;
  padding: 0 30px;
  margin-top: 30px;
}

.goods .tabs::-webkit-scrollbar{
  width: 0;
  height: 0;
  color: transparent;
}

.goods .tabs-block{
  width: 100%;
  height: 100px;
}

.goods .tabs-item {
  flex-basis: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #666666;
  white-space: nowrap;
  position: relative;
  padding-bottom: 20px;
  margin-right: 40px;
}

.goods .tabs-item:last-child {
  margin-right: 0!important;
}

.goods .active {
  font-size: 32px !important;
  color: #4085D5 !important;
  font-weight: bold !important;
}

.goods .current-filter {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 6px;
  background: #4085D5;
  border-radius: 4px 4px 4px 4px;
}

.goods .goods-more {
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 28px;
  color: #ABABAB;
  margin-top: 20px;
}
</style>
