export default [
  {
    path: '/goods/detail',
    name: 'goods-detail',
    component: () => import('@/views/goods/detail'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/goods/buy',
    name: 'goods-buy',
    component: () => import('@/views/goods/buy'),
    meta: {
      title: '确认订单'
    }
  },
  {
    path: '/goods/success',
    name: 'goods-success',
    component: () => import('@/views/goods/success'),
    meta: {
      title: '确认订单'
    }
  },
  {
    path: '/goods/address',
    name: 'goods-address',
    component: () => import('@/views/goods/address'),
    meta: {
      title: '我的收货地址'
    }
  },
  {
    path: '/goods/center',
    name: 'goods-center',
    component: () => import('@/views/goods/center'),
    meta: {
      title: '商品中心'
    }
  }
]
