<template>
  <div class="goods-wrap">
    <z-empty v-if="arrGoods.length==0 && showEmpty" title="暂无相关商品"></z-empty>
    <!-- 商品卡片 -->
    <div class="list" v-else>
      <div class="list-item" @click="onJumpUse(item)" v-for="(item,index) in arrGoods">
        <img class="list-item_img" :src="item.thumbnail"/>
        <div class="list-item_title z-line1">{{item.goodsName}}</div>
        <div class="list-item_price">
          <div class="list-item_price-new">￥<span class="price-new_num">{{item.goodsPrice}}</span><span class="list-item_price-original" v-if="item.originalPrice!=null">￥<span class="price-new_num">{{item.originalPrice}}</span></span></div>
        </div>
        <div class="list-item_sold">已售 {{item.salesStr}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  props: {
    dataGood: {
      type: Object,
      default: () => {
        return {}
      }
    },
    arrGoods: {
      type: Array,
      default: () => {
        return []
      }
    },
    areaId: {
      type: Number,
      default: null
    },
    activityCode: {
      type: Number,
      default: null
    },
    showEmpty: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
    }
  },

  methods: {
    onJumpUse(item) {
      api.jumpPage('goods-detail', { goodsCode: item.goodsCode })
    }
  }
}
</script>

<style lang="scss" scoped>

/* 商品卡片列表 */
.list {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(2, 335px);
  column-gap: 20px;
  row-gap: 20px;
  justify-content: space-between;
}

.list-item {
  width: 336px;
  min-height: 459px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 16px;
  position: relative;
}

.list-item_img {
  width: 336px;
  height: 316px;
  border-radius: 16px 16px 0px 0px;
  //background: #838FA1;
  margin-bottom: 10px;
  object-fit: cover;
}

.list-item_title {
  font-size: 28px;
  color: #666666;
  margin: 0 20px;
  font-weight: bold;
}

.list-item_price{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.list-item_price-new{
  font-size: 32px;
  font-weight: bold;
  color: #FF543A;
}

.price-new_num {
  margin-left: -6px;
}

.list-item_price-original{
  font-size: 28px;
  font-weight: 400;
  color: #ABABAB;
  text-decoration: line-through;
}

.list-item_sold {
  padding: 0px 20px;
  font-size: 22px;
  color: #ABABAB;
  margin-bottom: 10px;
}

.list-item_label {
  background-color: #FF543A;
  padding: 6px 5px;
  border-radius: 16px 0 16px 0;
  font-size: 20px;
  color: #FFFFFF;
  flex: none;
  position: absolute;
  min-width: 90px;
  max-width: 150px;
  height: 40px;
  top: 0px;
  left: 0px;
}

</style>
