import axios from 'axios'
import store from '@/store'
import api from '@/utils/api'
import router from '@/router'
import auth from '@/utils/auth'
import { v4 as uuidv4 } from 'uuid'
import { paramsEncrypt, decryptByAes, messageSignature, onDeb } from '@/utils/utils'
import qs from 'qs'

const baseURL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BL_CLINIC_API : window.envs.VUE_APP_BL_CLINIC_API
// 防抖跳转去手机登录页
const goLogin = onDeb((arr) => {
  // 走手机号登录流程
  router.replace({
    path: '/login',
    query: {
      redirectUrl: arr[0],
      param: qs.stringify(arr[1])
    }
  })
})

// 创建实例
const instance = axios.create({
  baseURL,
  timeout: 10000
})

// 请求前拦截器
instance.interceptors.request.use(
  (config) => {
    // 请求前做些什么
    if (config.loading) {
      api.showLoading(config.loadingText || '加载中')
    }
    if (config.contentType) {
      config.headers['content-type'] = config.contentType
    }

    // 设置token
    if(store.getters.getToken) {
      config.headers['Authorization'] = store.getters.getToken
    }
    config.headers['noncePf'] = uuidv4().replace(/-/g, '').toUpperCase()
    if (config.version) {
      config.headers['version'] = config.version
    }

    if(config.data) {
      config.data = {
        clinicCode: store.getters.getClinicCode,
        ...config.data
      }
      if (store.getters.getUserInfo.userId) {
        config.data.userId = store.getters.getUserInfo.userId
      }
      if (config.isSign) {
        config.data.sign = messageSignature(config.data)
      }
      if (config.encryptFlag === 1) {
        delete config.data.encryptFlag
        paramsEncrypt(config.data)
        config.data.encryptFlag = 1
      }
    }

    if(config.params) {
      config.params = {
        clinicCode: store.getters.getClinicCode,
        ...config.params
      }
      if (store.getters.getUserInfo.userId) {
        config.params.userId = store.getters.getUserInfo.userId
      }
      if (config.isSign) {
        config.params.sign = messageSignature(config.params)
      }
      if (config.encryptFlag === 1) {
        delete config.params.encryptFlag
        paramsEncrypt(config.params)
        config.params.encryptFlag = 1
      }
    }
    config.headers['appCode'] = 'HC_BL_WX'

    return config
  },
  (error) => Promise.reject(error)

)

// 响应拦截器
instance.interceptors.response.use(
  // 响应后做些什么
  async(response) => {
    api.hideLoading()
    const data = response.data || {}
    switch (data.code) {
      // 登录过期
      case '40000':
      case '410000':
      case '410001':
      case '410002':
      case 10001011:

        // 保留项
        const retainItems = [`CLINIC_CODE_${process.env.VUE_APP_MODE}`, `OPENID_${process.env.VUE_APP_MODE}`]
        // 清除用户信息
        store.commit('CLEAR_USER_INFO', retainItems)

        // api.toast('登录过期，请重新登录', 'error')

        // 重新进行登录流程
        // 当前路由信息
        const currentRoute = router.currentRoute || {}

        console.log(currentRoute, 5555)

        try {
          // 授权返回数据
          const data = await auth(currentRoute) || {}
          // 查询参数
          const query = qs.stringify(data.query)

          // 转向url
          const url = `${window.location.origin}${window.location.pathname}#${data.path}${query ? `?${query}` : ''}`
          // 通过replaceState强转页面
          if (window.history) {
            window.history.replaceState(null, document.title, url)
            window.history.go(0)
          } else {
            window.location.replace(url)
          }
        } catch(err) {
            let pageQuery = { ...currentRoute.query }
            const filterParams = ['openID', 'unionId', 'appId']
            // 过滤完拿到最终转向url
            filterParams.forEach((item) => {
              delete pageQuery[item]
            })
            // 走手机号登录流程
            // router.replace({
            //   path: '/login',
            //   query: {
            //     redirectUrl: currentRoute.path,
            //     param: qs.stringify(pageQuery)
            //   }
            // })
            goLogin(currentRoute.path, pageQuery)
        }

        return Promise.reject(data)
      case '200':
      case 0:
      case '0':
        if (response.config && response.config.params && response.config.params.encryptFlag === 1) {
          let decryptData = decryptByAes(data.data)
          try {
            decryptData = JSON.parse(decryptData)
          } catch (e) {
            decryptData = {}
          }
          return {
            code: data.code,
            data: decryptData
          }
        }
        return data
      default:
        api.toast(data.message || '服务器错误', 'error')
        return Promise.reject(data)
    }
  },
  (error) => {
    api.hideLoading()
    console.log(error)
    // 请求报错
    api.toast(error.message || '服务器错误', 'error')
    return Promise.reject(error)
  }
)

export default instance
