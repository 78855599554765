import api from '@/api/api'

// 获取商品详情页
export function getGoodsDetail(data) {
  return api.post({
    url: '/h5/goods/order/getGoodsDetail',
    data
  })
}

// 创建或获取商品订单接口
export function createOrGetGoodsOrder(data) {
  return api.post({
    url: '/h5/goods/order/createOrGetGoodsOrder',
    data
  })
}

// 商品订单支付
export function payGoodsOrder(data) {
  return api.post({
    url: '/h5/goods/order/payGoodsOrder',
    data
  })
}

// 确认收货操作
export function confirmReceipt(data) {
  return api.post({
    url: '/h5/goods/order/confirmReceipt',
    data
  })
}

// 修改商品订单相关信息
export function updateOrderSubInfo(data) {
  return api.post({
    url: '/h5/goods/order/updateOrderSubInfo',
    data
  })
}

// 获取指定类别页面商品信息
export function getSortPageGoods(data) {
  return api.get({
    url: '/h5/home/page/getSortPageGoods',
    data
  })
}

// 用户取消申请退款
export function cancelRefundApply(data) {
  return api.post({
    url: '/h5/goods/order/cancelRefundApply',
    data
  })
}

// 获取商品组件可选类别列表
export function getGoodsSortList(data) {
  return api.get({
    url: '/h5/home/page/getGoodsSortList',
    data
  })
}
