/**
 * 基本方法
 * sendCode 发送验证码60秒计时
 * passMob 手机号脱敏处理
 * copyText 一键复制文本内容
 * downBase64File 下载base64文件
 * downFile 下载文件流
 * downLoadImg 下载图片地址和图片名
 * getDatePattern 时间格式化
 * getMiniTime 日期转换时间戳
 * formateTime 将时间戳转为时间
 * getDateSub 获取对比今天前几天或后几天的日期
 * accAdd 计算 - 加法
 * accSub 计算 - 减法
 * accMul 计算 - 乘法
 * accDiv 计算 - 除法
 * onDeb 防抖
 * onThro 节流
 * isJSON 判断是否为正确的JSON字符串
 */

import api from '@/utils/api'
import Clipboard from 'clipboard'
import cryptoJS from 'crypto-js'

/**
 * 发送验证码60秒计时
 */
export function sendCode(codeSend, codeTime, canSend, max) {
  if (this.codeSend) {
    this.codeSend = false
    this.codeTime = max || 60
    const time = setInterval(() => {
      this.codeTime--
      if (this.codeTime <= 0) {
        this.codeSend = true
        this.canSend = true
        clearInterval(time)
      }
    }, 1000)
    console('eee', time)
  }
}

// 手机号脱敏处理
export function passMob(mob) {
  const pat = /(\d{3})\d*(\d{4})/
  const res = mob.replace(pat, '$1****$2')
  return res
}

/**
 * 一键复制文本内容
 * @param {String} e 点击事件
 * @param {String} text 复制内容
 * @param {String} desc 复制内容名称
 */
export function copyText(e, text, desc) {
  const clipboard = new Clipboard(e.target, {
    text: () => text
  })
  clipboard.on('success', (e) => {
    api.toast(`${desc}复制成功！`)
    // 释放内存
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.on('error', (e) => {
    // 不支持复制
    api.toast(`${desc}复制失败！`)
    // 释放内存
    clipboard.off('error')
    clipboard.off('success')
    clipboard.destroy()
  })
  clipboard.onClick(e)
}

/**
 * 下载base64文件
 * @param {String} file 资源
 * @param {String} fileName 下载后显示的文件名
 */
export function downBase64File(file, fileName) {
  const fileDownload = require('js-file-download') // 用于导出文件
  fileDownload(file, fileName)
}

/**
 * 下载文件流
 * @param {String} res 返回结果
 */
export function downFile(res) {
  const fileDownload = require('js-file-download') // 用于导出文件
  const str = res.headers['content-disposition']
  const fileName = decodeURI(str.substr(str.indexOf('%')))
  fileDownload(res.data, fileName)
}

/**
 * 下载图片地址和图片名
 * @param {String} imgSrc 图片链接
 * @param {String} name 名称
 */
export function downLoadImg(imgSrc, name = 'pic') {
  const alink = document.createElement('a')
  alink.href = imgSrc
  alink.download = name // 图片名
  alink.click()
}

/**
 * 下载图片地址和图片名
 * @param {String} imgSrc 图片链接
 * @param {String} name 名称
 */
export function downLoadImgCross(imgSrc, name = 'pic') {
  const image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function() {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    const url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

    const a = document.createElement('a') // 生成一个a元素
    const event = new MouseEvent('click') // 创建一个单击事件
    a.download = name || 'photo' // 设置图片名称
    a.href = url // 将生成的URL设置为a.href属性
    a.dispatchEvent(event) // 触发a的单击事件
  }
  image.src = imgSrc
}

/**
 * 时间格式化
 * @param {Date} date 日期
 * @param {String} fmt 格式化方式：要格式的类型 ('yyyy-MM-dd hh:mm:ss')/('yyyy-MM-dd')/('MM-dd')/('yyyy年MM月dd日')/('周E')
 * @return {String} 返回格式化后的日期
 *
 * 注：年、月、日、小时可以任意截取如：('yyyy') 、(hh ：12小时制、HH：24小时制)
 */
export function getDatePattern(date, fmt) {
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours() % 12 == 0 ? 12 : date.getHours() % 12,
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  }
  const week = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六'
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[`${date.getDay()}`])
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)))
    }
  }
  return fmt
}

/**
 * ios时间格式化
 * @param {Date} dateString 日期字符串
 */
export function getDateIos(dateString) {
  if (dateString) {
    dateString = dateString.replace(/-/g, '/')
    return dateString
  }
}

/**
 * 聊天截取时间
 * @param {Number} dateTimeStamp 传入的毫秒级时间戳
 * @return {String} 返回时间文字描述
 */
export function getDateChat(dateTimeStamp) {
  const minute = 1000 * 60 // 把分，时，天，周，半个月，一个月用毫秒表示
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7
  const month = day * 30
  const now = new Date().getTime() // 获取当前时间毫秒
  const diffValue = now - dateTimeStamp // 时间差
  if (diffValue < 0) {
    return
  }
  const minC = parseInt(diffValue / minute) // 计算时间差的分，时，天，周，月
  const hourC = parseInt(diffValue / hour)
  const dayC = parseInt(diffValue / day)
  const weekC = parseInt(diffValue / week)
  const monthC = parseInt(diffValue / month)
  let result = ''

  const datetime = new Date()
  datetime.setTime(dateTimeStamp)
  const Nyear = datetime.getFullYear()
  const Nmonth = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
  const Ndate = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
  const Nhour = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
  const Nminute = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
  const Nsecond = datetime.getSeconds() < 10 ? `0${datetime.getSeconds()}` : datetime.getSeconds()
  if (dayC >= 1) {
    result = `${Nyear}-${Nmonth}-${Ndate}`
  } else {
    result = `${Nhour}:${Nminute}`
  }
  return result
}

/**
 * 日期转换时间戳
 * @param {string} strDate 日期格式为 2020-01-01
 * @return {String} 返回时间戳
 */
export function getMiniTime(strDate) {
  return new Date(strDate.replace(/-/g, '/')).getTime() / 1000
}

// 将时间戳转为时间
export function formateTime(secondTime) {
  const time = secondTime
  let newTime
  let hour
  let minite
  let seconds
  if (time >= 3600) {
    hour = parseInt(time / 3600) < 10 ? `0${parseInt(time / 3600)}` : parseInt(time / 3600)
    minite = parseInt(time % 60 / 60) < 10 ? `0${parseInt(time % 60 / 60)}` : parseInt(time % 60 / 60)
    seconds = time % 3600 < 10 ? `0${time % 3600}` : time % 3600
    if (seconds > 60) {
      minite = parseInt(seconds / 60) < 10 ? `0${parseInt(seconds / 60)}` : parseInt(seconds / 60)
      seconds = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60
    }
    newTime = `${hour}:${minite}:${seconds}`
  } else if (time >= 60 && time < 3600) {
    minite = parseInt(time / 60) < 10 ? `0${parseInt(time / 60)}` : parseInt(time / 60)
    seconds = time % 60 < 10 ? `0${time % 60}` : time % 60
    newTime = `00:${minite}:${seconds}`
  } else if (time < 60) {
    seconds = time < 10 ? `0${time}` : time
    newTime = `00:00:${seconds}`
  }
  return newTime
}

/**
 * 获取对比今天前几天或后几天的日期
 * @param {Number} dayNum 天数
 * @return {String} 返回日期 2020/01/02
 */
export function getDateSub(dayNum) {
  const today = new Date() // 今天

  const day = dayNum * 24 * 60 * 60 * 1000 // 每天的时间戳差值

  const todayTimeStamp = today.getTime() // 今天的时间戳

  const finalDay = new Date(todayTimeStamp - day) // 最后一天的时间戳 ，几天前是减 ，几天后就改成加

  const Y = finalDay.getFullYear() // 年

  let M = finalDay.getMonth() + 1 // 月

  let D = finalDay.getDate() // 日

  // 将月和日格式标准化组合成需要的格式

  if (M.toString().length === 1) {
    M = `0${M}`
  }

  if (D.toString().length === 1) {
    D = `0${D}`
  }

  return `${Y}/${M}/${D}`
}

/**
 * 计算 - 加法
 * @param {String|Number} arg1 数字1
 * @param {String|Number} arg2 数字2
 */
export function accAdd(arg1 = 0, arg2 = 0) {
  let r1; let r2; let m; let
    c
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  c = Math.abs(r1 - r2)
  m = 10 ** Math.max(r1, r2)
  if (c > 0) {
    const cm = 10 ** c
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', '')) * cm
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm
      arg2 = Number(arg2.toString().replace('.', ''))
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''))
    arg2 = Number(arg2.toString().replace('.', ''))
  }
  return (arg1 + arg2) / m
}

/**
 * 计算 - 减法
 * @param {String|Number} arg1 数字1
 * @param {String|Number} arg2 数字2
 */
export function accSub(arg1 = 0, arg2 = 0) {
  let r1; let r2; let m; let
    n
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = 10 ** Math.max(r1, r2) // last modify by deeka //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

/**
 * 计算 - 乘法
 * @param {String|Number} arg1 数字1
 * @param {String|Number} arg2 数字2
 */
export function accMul(arg1 = 0, arg2 = 0) {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    console.log(e)
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    console.log(e)
  }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / 10 ** m
}

/**
 * 计算 - 除法
 * @param {String|Number} arg1 数字1
 * @param {String|Number} arg2 数字2
 */
export function accDiv(arg1 = 0, arg2) {
  if (!arg2) {
    return
  }
  let t1 = 0; let t2 = 0; let r1; let r2
  try {
    t1 = arg1.toString().split('.')[1].length
  } catch (e) {
    console.log(e)
  }
  try {
    t2 = arg2.toString().split('.')[1].length
  } catch (e) {
    console.log(e)
  }
  r1 = Number(arg1.toString().replace('.', ''))
  r2 = Number(arg2.toString().replace('.', ''))
  return (r1 / r2) * 10 ** (t2 - t1)
}

/**
 * 判断是否为正确的JSON字符串
 * @param {String} str
 * @return {Boolean}
 */
export function isJSON(str) {
  if (typeof str === 'string') {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  } else {
    return false
  }
}

/**
 * 防抖
 * @param {String} fn 方法
 * @param {String} delay 延迟时间
 * @return null
 */
export function onDeb(fn, delay = 300) {
  let timer
  return function() {
    clearTimeout(timer)
    const context = this
    const args = arguments// 保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
    timer = setTimeout(() => {
      fn.call(context, args)
    }, delay)
  }
}

/**
 * 节流
 * @param {String} fn 方法
 * @param {String} delay 延迟时间
 * @return null
 */
export function onThro(fn, delay = 300) {
  let enterTime = 0// 触发的时间
  return function() {
    const context = this
    const backTime = new Date()// 第一次函数return即触发的时间
    if (backTime - enterTime > delay) {
      fn.call(context, arguments)
      enterTime = backTime// 赋值给第一次触发的时间，这样就保存了第二次触发的时间
    }
  }
}

/**
 * 富文本重写
 * @param {String|Number} richText 富文本
 */
export function getWriteTextMini(richText) {
  if (richText) {
    richText = richText.replace(/blob:/gi, '')
    // 删除空的style属性，避免因为和已有的style属性冲突导致不显示
    richText = richText.replace(/style=""/gi, '')
    richText = richText.replace(/<img/gi, '\<img class="w-img"')
    // 视频
    richText = richText.replace(/<video/gi, '\<video class="w-video"')
  }
  return richText
}

// 获取url参数
export function getQueryString(name) {
  const search = window.location.search.substr(1) || (window.location.hash.split('?')[1] || '')
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = search.match(reg)
  if (r != null) return decodeURIComponent(r[2]); return null
}
/*
* 以字典序升序排列
* */
function objKeySort(jsonData) {
  try {
    let tempJsonObj = {}
    let sdic = Object.keys(jsonData).sort()
    sdic.map((item, index)=>{
      tempJsonObj[item] = jsonData[item]
    })
    return tempJsonObj
  } catch(e) {
    return jsonData
  }
}
// md5 验签加密
export function md5SV(obj = {}, params = {}) {
  obj = JSON.parse(JSON.stringify(obj))
  let md5Result
  let url = ''
  for (let key in params) {
    obj[key] = params[key]
  }
  let result = objKeySort(obj)
  for (let key in result) {
    if(typeof obj[key] === 'object'){
      url = `${url}${key}=${JSON.stringify(obj[key])}`
    }else{
      url = `${url}${key}=${obj[key]}`
    }
  }
  md5Result = cryptoJS.MD5(url).toString().toUpperCase()

  return md5Result
}

/*
短信加密的处理
* */
export function messageSignature(obj){
  let md5Result
  let url = ''
  /*
  需要加入时间戳处理
  * */
  obj['timeHashPf'] = new Date().getTime()
  let APP_ID = 'zoe-health'
  let SECRET_KEY = '3f7e609f0a22108e'
  let result = objKeySort(obj)
  for(let key in result){
    url = `${url}${key}=${obj[key]}`
  }
  url = `${url}${APP_ID}${SECRET_KEY}`
  md5Result = cryptoJS.MD5(url)
  md5Result = md5Result.toString().toUpperCase()
  return md5Result
}

const noEncryptionArr = ['appCode', 'token', 'timeHashPf', 'encryptFlag'] // 入参可不进行加密的key
export function paramsEncrypt(obj) {
  for(let key in obj) {
    if(typeof obj[key] === 'object') {
      obj[key] = encryptByAes(JSON.stringify(obj[key]))
    }else {
      if(obj[key] !== undefined && noEncryptionArr.indexOf(key) === -1) {
        let val = obj[key].toString() // 转为字符串才能加密
        obj[key] = encryptByAes(val)
      }
    }
  }
}

/**
 * AES加密
 * params:
 * msg:要加密的数据
 * key：密钥
 * viStr: 偏移量的计算
 * */
function encryptByAes(msg, key = '3132333435363738393041424344454631323334353637383930414243444566', ivStr = '30313233343536373839414243444546') {
  key = cryptoJS.enc.Hex.parse(key)
  const iv = cryptoJS.enc.Hex.parse(ivStr)
  let enc = cryptoJS.AES.encrypt(msg, key, {
    iv,
    mode: cryptoJS.mode.CBC, // 使用CBC模式
    padding: cryptoJS.pad.Pkcs7
  })
  const enced = enc.ciphertext.toString()
  return enced
}
/**
 * AES解密
 * params:
 * msg:要解密的数据
 * key：密钥
 * viStr: 偏移量的计算
 * */
export function decryptByAes(msg, key = '3132333435363738393041424344454631323334353637383930414243444566', ivStr = '30313233343536373839414243444546') {
  key = cryptoJS.enc.Hex.parse(key)
  const iv = cryptoJS.enc.Hex.parse(ivStr)
  const dec = cryptoJS.AES.decrypt(cryptoJS.format.Hex.parse(msg), key, {
    iv,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7
  })
  const deced = cryptoJS.enc.Utf8.stringify(dec)
  return deced
}
